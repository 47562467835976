import { ISearchParams } from "../Interfaces";

export const filterNull = (values: any) => {
  Object.keys(values).forEach((key) => {
    if (values[key] === null || values[key] === "") {
      delete values[key];
    } else if (`${values[key]}` === "NaN") {
      delete values[key];
    }
  });
  return values;
};


export const convertSearchParamsToQueryString = (values: ISearchParams) =>{
  const query_params = Object.keys(values).filter((k: string) => values[k as keyof ISearchParams]).map(function(k: string) {
    const encodedName = encodeURIComponent(k);
    const encodedValue = encodeURIComponent(values[k as keyof ISearchParams])
    return  `${encodedName}=${encodedValue}`; 
  }).join('&');
  return query_params
}