import React from 'react'
import noImage from "../images/placeholder.jpg";

function CarImage(props: any) {
  const { url, onClick } = props;
  return (
    <div>
    <div className='d-block gallery-image'>
      <img
        onClick={onClick}
        src={url}
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = noImage;
        }}
        alt='vehicle'
      />
    </div>
    </div>
  )
}

export default CarImage
