import React from "react";
import { Store } from "../Store";
import CarSearchHome from "./CarSearchHome";

export default function DealershareHome(): JSX.Element {
  const { state, dispatch } = React.useContext(Store);

  return (
    <>
      <div className='wrapper'>
          <CarSearchHome></CarSearchHome>
      </div>
    </>
  );
}
