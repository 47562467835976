import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { loadAuthToken, removeAuthToken, storeAuthToken } from "./Api";
import LoginHome from "./auth/LoginHome";
import { Store } from "./Store";
import DealershareHome from "./dealershare/DealershareHome";
import "./styles/index.scss";
import "./styles/styles.css";
import AlertSuccess from "./input/AlertSuccess";
import AlertDismiss from "./input/AlertError";

export default function Home() {
  const { state, dispatch } = React.useContext(Store);
  const handleAuthToken = (authToken: any) => {
    dispatch({ type: "FETCH_AUTH", payload: authToken });
    storeAuthToken(authToken);
  };

  const logOut = () => {
    dispatch({ type: "FETCH_AUTH", payload: "" });
    removeAuthToken();
  };

  useEffect(() => {
    const authToken = loadAuthToken();
    dispatch({ type: "FETCH_AUTH", payload: authToken });
  }, []);

  const getStoreAccessId = () => {
    return state.authToken && state.authToken.access
      ? state.authToken.access
      : "";
  };

  return (
    <div>
      {!getStoreAccessId() && <LoginHome handleAuthToken={handleAuthToken} />}
      {getStoreAccessId() && state.authToken.access && (
        <div>
          <div className='nav-bar'>
            <div className='logo-sm'></div>
           
           <div className='icon current'>
              <button className='icon-search'></button>
            </div>
            {/* <div className='icon'>
              <button className='icon-message'></button>
            </div>
             <div className='icon'>
              <button className='icon-charts'></button>
            </div> 
            <div className='icon'>
              <button className='icon-account'></button>
            </div>*/}
            
             <input id='logout' type='button' value='Logout' onClick={logOut} />
          </div>
          <AlertSuccess />
          <AlertDismiss />
          <Route
            path='/'
            exact={true}
            render={() => <DealershareHome />}
          ></Route>
        </div>
      )}
    </div>
  );

  
}
