import React from "react";
import { IAction, ISearchParams, IState } from "./Interfaces";

const initialState: IState = {
  authToken: { access: "", refresh: "" },
  searchParams: {} as ISearchParams,
  error_message: "",
};

export enum StoreCasesEnum {
  FETCH_AUTH = "FETCH_AUTH",
  FETCH_SEARCH_PARAMS = "FETCH_SEARCH_PARAMS",
  FETCH_ERROR_MESSAGE = "FETCH_ERROR_MESSAGE",
  FETCH_SUCCESS_MESSAGE = "FETCH_SUCCESS_MESSAGE",
}
export const Store = React.createContext<IState | any>(initialState);

export function StoreProvider(props: any): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <Store.Provider value={{ state, dispatch }}>
      {props.children}
    </Store.Provider>
  );
}

function reducer(state: IState, action: IAction) {
  switch (action.type) {
    case StoreCasesEnum.FETCH_AUTH:
      return { ...state, authToken: action.payload };
    case StoreCasesEnum.FETCH_SEARCH_PARAMS:
      const newState = {
        ...state,
        searchParams: { ...state.searchParams, ...action.payload },
      };
      return newState;
    case StoreCasesEnum.FETCH_ERROR_MESSAGE:
      return {
        ...state,
        error_message: action.payload,
      };
    default:
      return state;
  }
}
