import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import _ from "lodash";
import InputComponentCheckboxRadio from "./InputComponentCheckboxRadio";

const InputComponent = (fieldsProps) => {
  const {
    type,
    label,
    disabled,
    onChange,
    field,
    uniqueSelect,
    options,
    allowNew,
    placeholder,
    multiple,
    emptyLabel,
    form: { touched, errors, values, setFieldValue, setFieldTouched, dirty },
    ...props
  } = fieldsProps;


  const { name, onBlur } = field;
  const value = _.get(values, name);

  let InputEl = <input />;
  switch (type) {
    case "select":
      InputEl = (<select
        id={name}
        placeholder={label}
        name={name}
        {...field}
        style={{ display: "block" }}
      >
        <option value='' label={placeholder} />
        {options?.map((item, index) => {
          return (
            <option
              key={index}
              value={item.id}
              label={`${item.label}`}
            />
          );
        })}
      </select>)
      break;
    case "typeahead":
        const isOptionAnObject = Object.keys(options).length > 0 && typeof options[0] === 'object' ? true : false;
        const onChange = (options1) => {
            if (multiple) {
              const results = options1.filter(option => !option.id).map((option) => option.id);
              setFieldValue(name, results);
            } else {
              if (Array.isArray(options1) && options1.length > 0 && options1[0].id) {
                  setFieldValue(name, options1[0].id);
              } else {
                setFieldValue(name, ['']);
              }
            }
        };

        const selected = () => {
            if (multiple) {
              return options.filter(option => value?.includes(option.id))
            } else {
                return options.filter(option => value === option.id)
            }
        }

      InputEl = (
        <>
          <Typeahead
            id={name}
            name={name}
            allowNew={allowNew}
            options={options}
            multiple={false}
            selected={selected()}
            placeholder={placeholder}
            onBlur={() => setFieldTouched(name, true)}
            onChange={(selected_options) => onChange(selected_options)}
          />
        </>
      );
      break;
    case 'checkbox':
    case 'radio':
      InputEl = <InputComponentCheckboxRadio {...fieldsProps}></InputComponentCheckboxRadio >
      break;
    default:
      break;
  }
  return InputEl;
};
export default InputComponent;
