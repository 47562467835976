import axios from 'axios'
import { IAuthToken } from './Interfaces';

const AUTH_TOKEN = "authToken";

const API_ROOT = process.env.REACT_APP_API_BASE;
const session = axios.create({
  // withCredentials: true,
  baseURL: API_ROOT,
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
})

export const loadAuthToken = () => {
  const authTokenStr = localStorage.getItem(AUTH_TOKEN)
  const authToken = authTokenStr ? JSON.parse(authTokenStr) : null;
  return authToken;
}

export const getAccessToken = () => {
  const authToken = loadAuthToken();
  return authToken ? authToken.access : '';
}

export const storeAccessToken = (access: string) => {
  const authToken = loadAuthToken();
  const newAuthToken = {...authToken, access}
  storeAuthToken(newAuthToken)
}

export const getRefreshToken = () => {
  const authToken = loadAuthToken();
  return authToken ? authToken.refresh : '';
}

export const storeAuthToken = (authToken: IAuthToken) => {
  localStorage.setItem(AUTH_TOKEN, JSON.stringify(authToken));
}

export const removeAuthToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
}

export const refreshAccessToken = async () => {
  const res = await session
    .post(`${API_ROOT}auth/token/refresh/`, {
      refresh: getRefreshToken()
    })
  return res.data.access;
};

export const handleLogin = async (email: any, password: any) => {
  const res = await session
    .post(`${API_ROOT}auth/token/`, {
      email,
      password,
    })
  return res.data;
};



