import { Alert } from "react-bootstrap";

function AlertSuccess(props: any) {
  const {errorMessage, setErrorMessage} = props;

  const closeAlert = () => {
    setErrorMessage('')
  };

  return (
    <>
    <Alert show={!!errorMessage} variant='danger' onClose={closeAlert} dismissible>
      <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
      <p>{errorMessage}</p>
    </Alert>
    </>
  );
}
export default AlertSuccess;
