import { Field, Formik, validateYupSchema, yupToFormErrors } from "formik";

const MyEnhancedForm = (Component: any, schema: any) => (props: any) => {
  const { routeInitialValues, onSubmit } = props;
  const initialValues = routeInitialValues ? routeInitialValues : {};

  return (
    <div>
      <Formik
        initialValues={initialValues}
        initialErrors={{}}
        initialTouched={{}}
        onSubmit={onSubmit}
        validate={(values) => {
          // https://github.com/formium/formik/issues/506
          if (schema) {
            try {
              validateYupSchema(values, schema, true, values);
            } catch (err) {
              const errors = yupToFormErrors(err); //for rendering validation errors
              if (Object.keys(errors).length === 0) {
                console.error(err);
                return { application: "Yup is unable to parse the error object", err: err.message }
              } else {
                // console.log(errors)
                return errors;
              }
            }
          }
          return {};
        }}
        // validationSchema={schema}
        validateOnChange={false}
        validateOnMount={false}
        enableReinitialize={true}
        children={(formikBag) => (
          <>
            <Component formikBag={formikBag} {...props} />
          </>
        )}
      />
    </div>
  );
};
export default MyEnhancedForm;