import React from 'react'
import _ from 'lodash'


function InputComponentCheckboxRadio(props) {
  const { field: { name }, form: { setFieldValue, setFieldTouched, values }, type, options, multiple, wrapperClass, childClass, isSwitch, label, required, readOnly, className, isDisabled } = props;
  const value = _.get(values, name);

  const onChange = (e, checkedValue) => {
    let valueNew = ''
    if (multiple) {
      if (e.target.checked) {
        valueNew = [...value, checkedValue];
        setFieldValue(name, valueNew);
      } else {
        const index = value?.indexOf(checkedValue);
        if (index > -1) {
          valueNew = [...value];
          valueNew.splice(index, 1);
          setFieldValue(name, valueNew);
        }
      }
    } else {
      valueNew = value === checkedValue ? '' : checkedValue
      setFieldValue(name, valueNew);
    }
  }

  const getCheckedValue = (checkedValue) => {
    let result;
    if (multiple) {
      result = value?.includes(checkedValue) ? true : false;
    } else {
      result = checkedValue === value ? true : false
    }
    return result
  };

  return (
    <div>
    {options.map((option, index) =>
        <div key={`${name}-${index}`} className={`d-flex flex-row ${childClass ? childClass : "col-12"} ${isSwitch ? "switch" : null}`}>
            <input
            type={type}
            id={`${name}.${index}`}
            name={name}
            readOnly={readOnly}
            required={required}
            className={`mr-2 ${className}`}
            onChange={(e) => onChange(e, option[0])}
            onBlur={() => setFieldTouched(name, true)}
            disabled={isDisabled}
            checked={getCheckedValue(option[0])}
            />
            {isSwitch ?
            <span class="slider round"></span>
            :
            <span className="checkmark"></span>
            }
            <label htmlFor={`${name}.${index}`}>
            {option[1]}
            </label>
        </div>
    )}
    </div>)
}

export default InputComponentCheckboxRadio;