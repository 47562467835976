import React, { useState } from "react";
import { handleLogin } from "../Api";
// import { fetchStudents } from "../Api";
// import { IAction, IStudent } from "../Interfaces";

// const StudentList = React.lazy<any>(() => import ('./StudentList'));

export default function LoginHome(props: any): JSX.Element {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e: any) => {
    e?.preventDefault();
    const authInfo = await handleLogin(email, password);
    props.handleAuthToken(authInfo);
  };

  return (
    <form onSubmit={handleSubmit} className='login'>
      
        <div className='logo-container'>
          <div className="logo"></div>
          <h1>DEALER SHARE</h1>
        </div>
        <div className='form-container'>
          <label htmlFor='email'>Email</label>
          <input
            id='email'
            className='input-field'
            type='text'
            placeholder='email@dealershare.com'
            name='email'
            required
            onChange={(e: any) => setEmail(e.target.value)}
          />

          <label htmlFor='password'>Password</label>
          <input
            id={"password"}
            className='input-field'
            type='password'
            placeholder='super secret'
            name='password'
            required
            onChange={(e: any) => setPassword(e.target.value)}
          />

          <button type='submit' className='btn-primary'>
            LOGIN
          </button>
        </div>
    </form>
  );
}
