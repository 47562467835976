import axios from 'axios';
import React, { useState } from 'react'
import { BrowserRouter as Router } from "react-router-dom"
import { getAccessToken, refreshAccessToken, storeAccessToken } from './Api';
import Home from './Home'
import AlertError from './input/AlertError';
import {StoreProvider} from './Store'

const API_ROOT = process.env.REACT_APP_API_BASE;
const session = axios.create({
  withCredentials: true,
  baseURL: API_ROOT,
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
})

window.session = session;

// Request interceptor for API calls
session.interceptors.request.use(
  async config => {
    config.headers = { 
      'Authorization': `Bearer ${getAccessToken()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    return config;
  },
  error => {
    Promise.reject(error)
});



export default function App() {

  const [ errorMessage, setErrorMessage ] = useState('');
  // Response interceptor for API calls
  session.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      storeAccessToken(access_token)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return session(originalRequest);
    } else if ([200, 201].includes(error?.response?.status)) {
      return session(originalRequest);
    } else {
      setErrorMessage(error.message)
    }
    return Promise.reject(error);
  });

  return (
    <div className="App">
      <StoreProvider>
        <Router>
          <AlertError errorMessage={errorMessage} setErrorMessage={setErrorMessage}></AlertError>
          <Home />
        </Router>
      </StoreProvider>
    </div>
  )
}
