import { ISearchParams } from '../Interfaces';
import queryString from 'query-string'

export const fetchCars = async (searchParams: ISearchParams) => {
  const URL = `api/dealershare/cars/`;
  let config: any = {params: searchParams};
  const res = await window.session.get(URL, config);
  return res.data;
};

export const fetchDataProperties = async (searchParams: ISearchParams) => {
  const URL = `api/dealershare/dataproperties/`;
  let config: any = {params: searchParams};
  const res = await window.session.get(URL, config);
  return res.data;
};

export const fetchCarsWithUrl = async (url: string) => {
  const q = queryString;
  const parsedUrl = q.parseUrl(url)
  const url2 = queryString.stringifyUrl({url:"api/dealershare/cars/", query:parsedUrl.query});
  let config: any = {};
  // config.headers = {Authorization: `Bearer ${accessToken}`};
  const res = await window.session.get(url2, config);
  return res.data;
};

export const fetchMakesModelsVTrims = async (make?: string, model?: string) => {
  const URL = `api/dealershare/makesmodelsvtrims/`;
  let params: any = {};
  if (make) {
    params['make'] = make
  }
  if (make && model) {
    params.make = make
    params.model = model
  }
  let config: any = {params};
  // config.headers = {Authorization: `Bearer ${accessToken}`};
  const res = await window.session.get(URL, config);
  return res.data;
};

// export const fetchModels = async (make: string) => {
//   const URL = `api/dealershare/makesmodelsvtrims/`;
//   let config: any = {params:{make}};
//   // config.headers = {Authorization: `Bearer ${accessToken}`};
//   const res = await window.session.get(URL, config);
//   return res.data;
// };

export const fetchColors = async () => {
  const URL = `api/dealershare/colors/`;
  let config: any = {};
  // config.headers = {Authorization: `Bearer ${accessToken}`};
  const res = await window.session.get(URL, config);
  return res.data;
};

export const fetchDealers = async () => {
  const URL = `api/dealershare/dealers/`;
  let config: any = {};
  // config.headers = {Authorization: `Bearer ${accessToken}`};
  const res = await window.session.get(URL, config);
  return res.data;
};

export const fetchCategories = async () => {
  const URL = `api/dealershare/categories/`;
  let config: any = {};
  // config.headers = {Authorization: `Bearer ${accessToken}`};
  const res = await window.session.get(URL, config);
  return res.data;
};

export const fetchYears = async () => {
  const URL = `api/dealershare/years/`;
  let config: any = {};
  // config.headers = {Authorization: `Bearer ${accessToken}`};
  const res = await window.session.get(URL, config);
  return res.data;
};