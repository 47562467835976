export const mileageRanges =[
  {id:"1",label:"0 - 10,000"},
  {id:"2",label:"10,001 - 25,000"},
  {id:"3",label:"25,001 - 50,000"},
  {id:"4",label:"50,001 - 100,000"},
  {id:"5",label:"100,000 - 150,000"},
  {id:"6",label:"150,001 - 200,000"},
  {id:"7",label:"200,000+"}
]

export const pageSizes =[
  {id:"1",label:"1"},
  {id:"10",label:"10"},
  {id:"20",label:"20"},
  {id:"50",label:"50"},
  {id:"100",label:"100"}
]

