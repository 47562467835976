import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useMemo, useState } from "react";
import { IDataProperty, IPaginatedObject } from "../Interfaces";
import CarImages from "./CarImages";

function CarResultsDataProperties(props:{dataProperties: IDataProperty[]}) {
  const {dataProperties}  = props;
    const headerPropsMap: any = {
      "total_car_count":{
        label:"Total Cars",
        type: "number"
      },
      "generation_process_created":{
        label:"Last updated",
        type: "date"
      },
      "total_dealer_count":{
        label:"Total Dealers",
        type: "number"
      },
      "results":{
        label:"Reults",
        type: "number"
      },
      "avg_mileage":{
        label:"Average Mileage",
        type:"decimal"
      },
      "avg_price":{
        label:"Average Price",
        type: "money"
      },
    }

    const getName = (key: string) => {
      const headerProp = headerPropsMap[key as keyof any];
      return headerProp.label;
    }

    const getValue = (key: string, value: any) => {
      const headerProp = headerPropsMap[key];
      const type =  headerProp.type;
      if (type === 'date'){
          return moment(value).fromNow();
      } else if (type === 'decimal') {
        return numeral(value).format('0,0')
      } else if (type === 'money') {
        return numeral(value).format('$ 0,0')
      } else if (type === 'number') {
        return numeral(value).format('0,0')
      } else {
        return value;
      }
    }

    return (
      <div className='search-results'>
        <div className='search-details'>
            {dataProperties.map((property: IDataProperty, index: number) => 
              <div className="result" key={index}>
                <h6>{getName(property.name)}</h6>
                <h4>{getValue(property.name, property.value)}</h4>
            </div>)}
        </div>
      </div>
    )
}

export default CarResultsDataProperties;
