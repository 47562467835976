import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import queryString from 'query-string';
import {
  fetchCars,
  fetchCarsWithUrl,
  fetchDataProperties,
} from "./Api";
import {
  IDataProperty,
  IPaginatedObject,
  ISearchParams,
} from "../Interfaces";
import { Store } from "../Store";
import CarResults from "./CarResults";
import { convertSearchParamsToQueryString, filterNull } from "../util/Util";
import CarSearchFilter from "./CarSearchFilter";
import { useLocation } from "react-router";
import CarResultsStats from "./CarResultsDataProperties";

export default function CarSearchHome(): JSX.Element {
  const location = useLocation();
  const [carPage, setCarPage] = useState<IPaginatedObject>({
    next: "",
    previous: "",
    results: [],
  });
  const [dataProperties, setDataProperties] = useState<IDataProperty[] | []>(
    []
  );

  const [loading, setLoading] = useState(true);
  const [loadingDataProperties, setLoadingDataProperties] = useState(false);


  const handleCarsWithUrl = async (url: string) => {
    setLoading(true);
    try {
      const carPage = await fetchCarsWithUrl(url);
      setCarPage(carPage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    async function load() {
      setLoading(true);
      const searchParams: ISearchParams = queryString.parse(location.search) as unknown as ISearchParams;
      try {
        const carPage = await fetchCars(searchParams);
        setCarPage(carPage);
      } finally {
        setLoading(false);
      }
    }
    load();
  },[location.search])


  useEffect(()=>{
    async function load() {
      setLoadingDataProperties(true);
      const searchParams: ISearchParams = queryString.parse(location.search) as unknown as ISearchParams;
      try {
        const dataProperties = await fetchDataProperties(searchParams);
        setDataProperties(dataProperties);
      } finally {
        setLoadingDataProperties(false);
      }
    }
    load();
  },[location.search])

  // const CarSearchFilter = React.lazy<any>(() => import("./CarSearchFilter"));

  return (
    <>
      <div className='aside'>
        <CarSearchFilter></CarSearchFilter>
        </div>
        <div className='main'>
        {loadingDataProperties ? <div className='loader'></div> : <CarResultsStats dataProperties={dataProperties} />}
        {loading ? <div className='loader'></div> : <CarResults carPage={carPage} />}
        <div className='results-footer'>
          {carPage?.previous && (
            <Button
              variant='secondary'
              onClick={() => handleCarsWithUrl(carPage.previous)}
            >
              Previous
            </Button>
          )}
          {carPage?.next && (
            <Button
              variant='primary'
              onClick={() => handleCarsWithUrl(carPage.next)}
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
