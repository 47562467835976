import { Field, Form, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import DebugFormik from "../formik/DebugFormik";
import MyEnhancedForm from "../formik/helper";
import { IMasterData, ISearchParams } from "../Interfaces";
import { useHistory, useLocation } from "react-router";
import queryString from 'query-string';
import { fetchColors, fetchDealers, fetchMakesModelsVTrims, fetchYears } from "./Api";
import { convertSearchParamsToQueryString } from "../util/Util";
import { mileageRanges, pageSizes } from "../util/srcData";
import _ from 'lodash';
import InputComponent from "../input/InputComponent";

const INIT_SEARCH_PARAMS: ISearchParams = {year: '', make:'', model:'', vtrim:'', interior_color:'', exterior_color:'',dealer:'', mileage_id:'' }  as ISearchParams;

function InternalForm(props: {
  formikBag: FormikProps<ISearchParams>;
  onReset: Function;
}) {
  const {formikBag:{values, setFieldValue, resetForm}} = props;
  const INIT_EMPTY_OPTION = {id:"", label:""}
  const [years, setYears] = useState<IMasterData[] | []>([]);
  const [makes, setMakes] = useState<IMasterData[] | []>([]);
  const [models, setModels] = useState<IMasterData[] | []>([]);
  const [vtrims, setVTrims] = useState<IMasterData[] | []>([]);
  const [colors, setColors] = useState<IMasterData[] | []>([]);
  const [dealers, setDealers] = useState<IMasterData[] | []>([]);
  const [mileages, setMileages] = useState<IMasterData[] | []>(mileageRanges);

  const buildModels = (items: any) => {
    const tModels = items?.filter((item: any) => !!item.model).map((item: any) => { return {id: item.model, label: item.model }});
    return tModels;
  }


  const buildVtrims = (items: any) => {
    const tVTrims = items?.filter((item: any) => !!item.vtrim).map((item: any) => { return {id: item.vtrim, label: item.vtrim }});
    return tVTrims;
  }

  useEffect(() => {
    async function load(){
      const years = await fetchYears();
      const tYears = years.map((item: any) => { return {id: `${item.code}`, label: `${item.code}` }});
      setYears(tYears);

      const makes = await fetchMakesModelsVTrims()
      const tMakes = makes.map((item: any) => { return {id: item.make, label: item.make }});
      setMakes(tMakes);

      if (values.make) {
        const models = await fetchMakesModelsVTrims(values.make);
        const tModels = buildModels(models);
        setMakes(tModels);
      }
      if (values.make && values.model) {
        const vtrims = await fetchMakesModelsVTrims(values.make, values.model)
        const tVTrims = buildVtrims(models);
        setVTrims(tVTrims);  
      }

      const colors = await fetchColors();
      const tColors = colors?.map((item: any) => {
        return { label: item.name, id: item?.id };
      })
      setColors(tColors);

      let dealers = await fetchDealers();
      const dealers1 = dealers?.filter((item: any) => item.dealer_id)?.map((item: any) => {
        return { label: `${item?.dealer__url} (${item.dealer_id__count})`, id: item?.dealer_id };
      })      
      const tDealers1 = _.orderBy(dealers1, ['url']);
      setDealers(tDealers1);
    };
    load();
  }, []);

  const handleReset = () => {
    resetForm(INIT_SEARCH_PARAMS as unknown as any)
    props.onReset();
  }

  useEffect(() => {
    async function load() {
      // setFieldValue('model', '');
      // setFieldValue('vtrim', '');
      if (values.make) {
        const data = await fetchMakesModelsVTrims(values.make);
        setModels(buildModels(data));
      } else {
        setModels([]);
      }
    }
    load();
  }, [values.make]);

  useEffect(() => {
    async function load() {
      // setFieldValue('vtrim', '');
      if (values.make && values.model) {
        const data = await fetchMakesModelsVTrims(
          values.make,
          values.model
        );
        setVTrims(buildVtrims(data));
      } else {
        setVTrims([]);
      }
    }
    load();
  }, [values.make, values.model]);

  const processT = (items: IMasterData[]) => {
    return [...items, INIT_EMPTY_OPTION];
  }

  return (
    <Form>
      <h3>Search Inventory</h3>
      <DebugFormik formikBag={props.formikBag}></DebugFormik>
      <Field
        name={"year"}
        component={InputComponent}
        type="typeahead"
        multiple={false}
        options={processT(years)}
        placeholder={"Year"}
        emptyLabel={"Select year"}
      ></Field>
      <Field
        name={"make"}
        component={InputComponent}
        type="typeahead"
        options={processT(makes)}
        placeholder={"Make"}
      ></Field>
      <Field
        name={"model"}
        component={InputComponent}
        type="typeahead"
        options={processT(models)}
        placeholder={"Model"}
      ></Field>
      <Field
        name={"vtrim"}
        placeholder={"Trim"}
        component={InputComponent}
        type="select"
        options={vtrims}
      ></Field>
      <Field
        name={"interior_color"}
        component={InputComponent}
        type="select"
        options={colors}
        placeholder={"Interior Color"}
      ></Field>
      <Field
        name={"exterior_color"}
        component={InputComponent}
        type="select"
        options={colors}
        placeholder={"Exterior Color"}
      ></Field>
      <Field
        name={"dealer"}
        component={InputComponent}
        type="typeahead"
        options={processT(dealers)}
        placeholder={"Dealer"}
      ></Field>
      <Field
        name={"mileage_id"}
        component={InputComponent}
        type="select"
        options={mileages}
        placeholder={"Mileage"}
      ></Field>      
      <hr />
      <Field
        name={"page_size"}
        component={InputComponent}
        type="select"
        options={pageSizes}
        placeholder={"Page Size"}
      ></Field>
      <Button className='btn' type='submit'>
        Search
      </Button>
      <Button className='btn' type='button' onClick={handleReset}>
        Reset
      </Button>
    </Form>
  );
}
const EnhancedForm = MyEnhancedForm(InternalForm, undefined);


const CarSearchFilter = (props: any) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState<ISearchParams>(INIT_SEARCH_PARAMS);
  const history = useHistory()

  useEffect(() => {
    const urlSearchParams: ISearchParams = queryString.parse(location.search) as unknown as ISearchParams;
    if(Object.keys(urlSearchParams).length > 0){
      setSearchParams(urlSearchParams);
    } 
  }, [location.search]);


  const handleSubmit = (values: ISearchParams) => {
    const query_params = convertSearchParamsToQueryString(values);
    history.push(`/?${query_params}`)
  }

  const handleReset = () => {
    setSearchParams({...INIT_SEARCH_PARAMS});
  }

  return (
    <>
      <EnhancedForm routeInitialValues={searchParams} onSubmit={handleSubmit} onReset={handleReset}/>
    </>
  );
};
export default CarSearchFilter;