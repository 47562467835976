import { Modal, Button } from "react-bootstrap";

function AlertError(props: any) {
  const {errorMessage, setErrorMessage} = props;

  const closeAlert = () => {
    setErrorMessage('')
  };

  return (
    <>
      <Modal show={!!errorMessage} onHide={closeAlert}>
        <Modal.Header closeButton>
          <Modal.Title>Error Occured</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{color: 'black'}}>
            {errorMessage}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeAlert}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AlertError;