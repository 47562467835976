import numeral from "numeral";
import React, { useEffect, useMemo, useState } from "react";
import { IPaginatedObject } from "../Interfaces";
import CarImage from "./CarImage";
import CarImages from "./CarImages";
import SelectedCarModal from "./SelectedCarModal";

function CarResults(props:{carPage: IPaginatedObject}) {


  // const [showModal, setShowModal] = useState(false);
  const [selectedCar, setSelectedCar] = useState();
  const {carPage} = props;

  const handleShowModal = (e: any, car: any) => {
    e?.preventDefault();
    setSelectedCar(car)
  }

  const handleHideModal = (e: any) => {
    e?.preventDefault();
    setSelectedCar(undefined)
  }

  return (
      <div>
        {/* <!---- VEHICLE GRID ----------------------------> */}
        <div className='grid'>
          {/* <!-- VEHICLE CARD ---> */}
          {selectedCar && <SelectedCarModal item={selectedCar} onHideModal={handleHideModal}></SelectedCarModal>}
          {carPage?.results?.length > 0 ? (
            carPage?.results?.map((item, index) => (
              <div className='item' key={index}>
                {/* {<CarImages car={item} showModal={showModal} onShowModal={handleShowModal} onHideModal={handleHideModal}/>} */}
                <CarImage
                  url={
                    item?.scrap_image_urls && item.scrap_image_urls.length > 0
                      ? item.scrap_image_urls[0]
                      : ""
                  }
                  onClick={(e: any) => handleShowModal(e, item)}
                />
                <div className='info'>
                  <div className='vehicle-title'>
                  {item.year} {item.make} {item.model}
                 </div>
                  <div className='dealer'>
                    <a
                      href={item?.scrap_listing_url}
                      target='_blank'
                      rel='noreferrer'
                      className='txt-link'
                    >
                      Dealership
                    </a>
                  </div>
                  <div className='numbers'>
                      <div className='detail'>
                        <div className='title'>Mileage</div>
                        <div className='miles'>{numeral(item.mileage).format("0,0")}</div>
                      </div>
                     {/*  <div className='detail'>
                        <div className='title'>Distance</div>
                        {/* <div className='miles'>{item.mileage}</div> 
                      </div>
                      <div className='detail'>
                        <div className='title'>Days</div>
                        {/* <div className='days'>{item.year}</div> 
                      </div> */}
                  </div>
                  
                  
                </div>
                <div className='cta'> 
                    <div className='pricing'>
                      <div className='price'>{numeral(item.price).format('$ 0,0')}</div>
                      {/* <div className='price-change'>
                          <span className='small'>09.30.22 - $134,567</span>
                      </div> */}

                      <div className='view'>
                       <a href="" className='btn-secondary' title="View More Info" onClick={(e) => handleShowModal(e, item)}>VIEW</a>
                      </div>
                    </div>
                     {/*<div className='actions'>
                      <div className='link'>
                        <a href="" className='btn-round' title="Add to List">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
</svg>
                        </a>
                      </div>
                      <div className='link'>
                        <a href="" className='btn-round' title="Call Dealer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16"><path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
</svg>
                        </a>
                      </div>
                      <div className='link'>
                        <a href="" className='btn-round' title="Message Dealer">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-right-fill" viewBox="0 0 16 16"><path d="M14 0a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z"/>
</svg>
                          
                        </a>
                      </div> 
                      <div className='view'>
                       <a href="" className='btn-secondary' title="View More Info" onClick={(e) => handleShowModal(e, item)}>VIEW</a>
                      </div>
                      
                    </div>*/}

                  </div> 
              </div>
            ))
          ) : (
            <div>
              <h3>Sorry, No Results</h3>
            </div>
          )}
        </div>
        {/* <!--END CARD--> */}
        {/* <!-- END GRID --> */}
      </div>
    );
}

export default CarResults;
