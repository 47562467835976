import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import JSONPretty from 'react-json-pretty';
function DebugFormik(props: any) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {name} = props;

  return (
    <>
      {process.env.REACT_APP_DEBUG && <>
        <Button variant="primary" onClick={handleShow}>
          {name ? name : 'Debug control'}
        </Button>

        <Modal show={show} onHide={handleClose} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Debug State</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{color: 'black'}}>
              <JSONPretty data={props.formikBag}></JSONPretty>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>}
    </>
  );
}
 export default DebugFormik;