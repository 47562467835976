import numeral from 'numeral';
import React from 'react'
import { Button, Carousel, CloseButton, ListGroup, Modal, Tab, Table, Tabs } from 'react-bootstrap';
import CarImage from './CarImage';
import "./SelectedCarModal.scss"

function SelectedCarModal(props:{onHideModal: any, item: any}) {
  const {onHideModal, item} = props;
  return (
    <div>
        <Modal size='lg' show={!!item} onHide={(e: any) => onHideModal(e, item)}>
        <Modal.Header>
          <div className="header-title">
            <Modal.Title>
                {item.year} {item.make} {item.model}
            </Modal.Title>

            {/* {dealer} | {address} 
            <p><strong>Dealer Name</strong><br/>1234 Main St. Buffalo, NY 14220</p>*/}
          </div>

          <CloseButton aria-label='Hide' onClick={onHideModal} />

        </Modal.Header>
        <Modal.Body className='gallery'>
            <div className='top'>
                  {/* --------- CAROUSEL -------------*/}
                  <Carousel>
                      {item?.scrap_image_urls?.map((url: string, index: number) => 
                        <Carousel.Item key={index}>
                          <CarImage url={url} key={index} />
                        </Carousel.Item>
                      )}
                    </Carousel>
                  {/* --------- CAROUSEL -------------*/}
                

                  {/* --------- VEHICLE INFO -------------*/}
                  <div className='vehicle-info'>
                        <div className='price'>
                          <div className="title">Current Price </div>
                          <h1 className='huge'>{numeral(item.price).format('$0,0')}</h1>
                        </div>

                        <div className='price-history'>
                          {/* <div className='title'>Price History</div>
                          <div className='price-list'>
                            <span></span>
                            <span></span>
                          </div>  */}
                        </div>

                        <div className='numbers'>
                          <div className='detail'>
                            <div className='title'>Mileage</div>
                            <div className='amount'>
                              {numeral(item.mileage).format('0,0')}<span> miles</span>
                            </div>
                          </div>
                         {/*  <div className='detail'>
                            <div className='title'>Distance</div>
                            <div className='amount'>
                              <span> </span>
                            </div>
                          </div>
                          <div className='detail'>
                            <div className='title'>Lot Age</div>
                            <div className='amount'><span></span></div>
                          </div> */}
                        </div>

                      
                        <div className='contact'>
                            {/* <h2>Contact Dealer</h2> */}
                            <div className="actions">
                             {/*  <Button variant='primary'>Call Now</Button>
                              <Button variant='primary'>Message</Button> 
                              <Button variant='primary' title="Contact Dealer">
                                {item?.dealer?.url}
                              </Button>*/}
                              <a
                                href={item?.scrap_listing_url}
                                target='_blank'
                                rel='noreferrer'
                                className='btn-secondary'
                              >
                                Contact Dealer
                              </a>
                            </div>
                        </div>
                  </div>
                  {/* --------- END VEHICLE INFO -------------*/}
            </div>

          {/* --------- TABBED FEATURES -------------*/}
            <div className="tabs">
              <Tabs defaultActiveKey="details" id="tab-details" className="mb-3">
                <Tab eventKey="details" title="Details">
                  <div className="vehicle-details">
                    <div className="specs">
                      <h5>[VIN:{item?.vin}]</h5>
                      {item.scrap_detailed_specifications?.map((specs: any) => 
                        <Table>
                            <tbody>
                          {Object.entries(specs).map((spec: any) => 
                              <>
                                <tr><div className="mt-4">{spec[0]}</div></tr><tr className='grey'>{spec[1].join(", ")}</tr>
                              </>
                          )}
                            </tbody>
                        </Table>
                      )}
                    </div>
                    {/* <div className="vehicle-all">
                      <h4>All Features</h4>
                      <div className="features-list">
                        <p></p>
                      </div>
                    </div> */}
                  </div>
                </Tab>
                {/* <Tab eventKey="data" title="Data">
                    <div className='specs'>
                      <h4>Charts &amp; Graphs</h4>
                        <p></p>
                     
                    </div>
                </Tab> */}
                <Tab eventKey="dealership" title="Dealership">
                  <div className='specs'>

                    {/* --- DEALERSHIP ---*/}                  
                    <div className='dealership'>
                      {/*<div className='dealer-title'>
                          <div className='logo'>Logo</div>
                          <div className='name'>
                            <h4>Dealership Name</h4>
                            <p className='address'>
                                1234 Main St., Buffalo, NY 14220
                            </p>
                          </div>
                      </div>*/}
                      {/* --- DEALERSHIP CONTACT---*/}   
                      <div className='dealer-contact'>
                            {/*<Button variant='link'><span className='icon-call'></span> 1.716.999.000</Button>*/}
                            <h4>Dealer Contact</h4>
                            <Button variant='link'>{item?.dealer?.url}</Button>
                      </div>
                    </div>



                    {/* <div className='staff'>
                        <h4>Dealer Share Managers</h4>
                        <div className='person'>
                          <h5>Joe Smith</h5>
                          <h6>Managing Director</h6>
                          <Button variant='link'><span className='icon-call'></span> 1.716.999.000</Button>
                          <Button variant='link'><span className='icon-email'></span> email@dealership.com</Button>
                        </div>
                        <div className='person'>
                          <h5>Joe Smith</h5>
                          <h6>Managing Director</h6>
                          <Button variant='link'><span className='icon-call'></span> 1.716.999.000</Button>
                          <Button variant='link'><span className='icon-email'></span> email@dealership.com</Button>
                        </div>
                      </div> */}
                    </div>
                </Tab>
              </Tabs>
            </div>
            {/* --------- END TABBED FEATURES -------------*/}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SelectedCarModal
